<template>
  <div class="Create">
    <div class="box">
      <div class="title">
        <span style="color: #fff">{{ userInfo.nickName }}</span>
        <span v-if="$route.query.type == 2">你好，欢迎进行续写创作！</span>
        <span v-else>你好，欢迎在[几人作]用AI创作！</span>
      </div>
      <div class="kuang">
        <span>第一步：兑换开篇创作权限</span>
        <!-- <el-button type="text" @click="visible = !visible">
          <i class="el-icon-info"></i> <span>说明</span></el-button
        >
        <div class="shuoMing" v-show="visible">
          <div class="item">
            <i class="el-icon-info"></i>
            <div class="tit">什么是NFT？</div>
            <div class="con">
              NFT是区块链网络里具有唯一性特点的可信数字权益凭证，每个NFT都有唯一编码，不可复制，不可篡改。
            </div>
          </div>
          <div class="item">
            <i class="el-icon-info"></i>
            <div class="tit">创作前为什么要先兑换NFT？</div>
            <div class="con">
              几人作汇集了“AIGC”与“Web3”两大互联网新技术，为创作者和阅读者打造了新型互利生态，不但可以让创作者从好的作品中持续收益，阅读者也不再是单方面的利益输出方，因此我们引入NFT作为创作者的权益标记，并结合智能合约技术让作者便捷、快速的通过NFT获取创作收益，更加公开、透明、可信！
            </div>
          </div>
          <div class="item">
            <i class="el-icon-info"></i>
            <div class="tit">NFT如何使用和保存？</div>
            <div class="con">
              创作者兑换并获得NFT后，即可享受与之绑定的AI创作权限，可在“我的数字资产”页中查看所有NFT并点击进入对应的创作界面。
              NFT的保存方式取决于用户账号的登录方式，如用户使用几人作账号进行登录，NFT获取后将由平台的区块链钱包代为保管，用户可随时将其提取到自己的链上钱包地址中；如果用户使用Matemask登录，那么NFT获取后将直接被领取到用户所使用的钱包地址中。
            </div>
          </div>
          <div class="item">
            <i class="el-icon-info"></i>
            <div class="tit">NFT是否可以转账或交易？</div>
            <div class="con">
              可以。由于NFT实质上是用户在区块链上的数字虚拟资产，用户可以根据自己需要随意转账或交易，但几人作有两点提示：
              -
              几人作不提供官方NFT交易社区，也不会为任何NFT交易行为提供风险担保。
              -
              几人作平台中每个NFT都有与之绑定的相关权益，转出NFT后原账号将失去对应权益。
            </div>
          </div>
        </div> -->
      </div>
      <div class="twoImgs" v-if="$route.query.type == 2">
        <div class="dataImg">
          <el-image
            style="height: 100%; width: 100%; object-fit: cover"
            :src="agoData.imageUrl"
            fit="cover"
          ></el-image>
          <div class="title">{{ agoData.title }}</div>
          <div class="bottom">
            <el-avatar
              shape="square"
              size="small"
              :src="agoData.authorHeadImage"
            />
            <span class="nickName">{{ agoData.authorName }}</span>
          </div>
        </div>
        <img
          src="@/assets/images/Continuation.png"
          style="width: 182px; height: 280px; margin-left: 20px"
          alt=""
        />
      </div>
      <div class="imgs" v-else>
        <img src="@/assets/images/creatImg.png" alt="" />
      </div>

      <div class="num">
        <span>
          <span style="color: #999"> 兑换价格：</span>
          {{ nftObj.createBalance }}</span
        >
        <img src="@/assets/images/eth.png" alt="" />
      </div>
      <div class="longline num">
        <span>
          <span style="color: #999"> 账户余额：</span
          >{{ nftObj.walletBalance }}</span
        >
        <img src="@/assets/images/eth.png" alt="" />
        <i class="el-icon-loading loading" v-show="isRefresh" />

        <span @click="refresh" class="refresh">
          <i class="el-icon-refresh" /> 刷新</span
        >
        <span @click="pay" class="pay">
          <i class="el-icon-edit-outline" /> 充值</span
        >
      </div>

      <el-button size="small" type="primary" class="button" @click="yesUpdeta"
        ><span style="color: #000; font-weight: 600">确认兑换</span>
      </el-button>
    </div>
    <el-dialog
      title="正在兑换NFT，我们需要验证你的用户身份"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <div class="blackcircle">
        <span>兑换支出：{{ nftObj.createBalance }}</span>
        <img src="@/assets/images/eth.png" alt="" />
      </div>
      <div class="word">
        <span style="margin-right: 100px"
          >将通过手机：{{ userInfo.phone }} 接收短信验证码</span
        >

        <el-button
          size="mini"
          round
          :disabled="code.dis"
          type="primary"
          @click="getCode"
        >
          <span style="color: #000; font-weight: 600" v-if="code.show"
            >发送短信</span
          >
          <span style="color: #828282; font-weight: 600" v-else
            >{{ code.count }}s后重发</span
          >
        </el-button>
      </div>
      <div class="word">请输入接收到的验证码：</div>
      <div class="haveCode" v-if="!code.show">
        <i class="el-icon-message" />短信验证码已发送，若未收到请耐心等待
      </div>
      <el-input v-model="mobileCode" placeholder="请输入验证码"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          class="footerBut"
          @click="dialogSbmit"
          ><span style="color: #000; font-weight: 600">提交</span>
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="充值ETH" :visible.sync="dialog1" width="700px">
      <div class="contentImg">
        <img
          style="height: 160px; width: 160px"
          src="@/assets/images/payETH.png"
        />
        <div class="contentRight">
          <div class="hui">主网：</div>
          <div class="hei" style="margin-bottom: 30px">Ethereum(ERC20)</div>
          <div class="hui">钱包地址：</div>
          <div class="hei">0x8e06b884026dccfd7d8bd1240a3821914d5bbca1</div>
          <img class="imgButton" src="@/assets/images/copy.png" @click="copy" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          style="color: #000; font-weight: 600; width: 80%"
          @click="dialog1 = false"
          ><span
            >请务必仔细核实转账网络以及接收地址，避免造成不必要的虚拟财产损失。</span
          >
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { payNft, createQueryBalance, createArticle } from "@/api/nft";
import { detail } from "@/api/article";
import { setSm3 } from "@/utils/sm3Util";
import { setSm4 } from "@/utils/sm4Util";
export default {
  name: "Create",
  data() {
    return {
      code: {
        dis: false,
        show: true,
        timer: null, //设置计时器,
        count: "",
      },
      agoData: {
        article: { title: "", pics: [{ url: "" }] },
        author: { avatar: "", name: "" },
      },
      nftObj: {},
      visible: false,
      dialogVisible: false,
      content: "",
      selectedText: "",
      mobileCode: "",
      isRefresh: false,

      dialog1: false,
    };
  },
  mounted() {
    this.getAgo();
    this.getMoney();
  },
  methods: {
    getMoney() {
      let param = {
        accountId: this.accountId,
        operType: 1, //1=开篇 2= 续篇
      };
      createQueryBalance(param).then(({ code, data }) => {
        if (code == 1000) {
          this.nftObj = data;
        }
      });
    },
    getAgo() {
      // 获取原创作信息
      if (this.$route.query.type == 2) {
        detail({ accountId: this.accountId, articleId: this.agoId }).then(
          ({ code, data }) => {
            if (code==1000) {
              this.agoData = data;
            }
          }
        );
      }
    },
    dialogSbmit() {
      let param = {
        parentArticleId: "", //开篇不用传值 续篇传被续作品ID
        accountId: this.accountId,
        verifyCode: this.mobileCode, //验证码
        sm3Sign: setSm3(this.nftObj.walletBalance), //余额SM3 签名，尾数不补去0 示例20.00 直接为20， 20.10 为20.1
        operType: 1, //操作类型 1=开篇 2 =续写
      };
      createArticle(param).then(({ code, data }) => {
        if (code == 1000) {
        // 存文章id
        this.$store.commit("SETAGOID", data);
          this.dialogVisible = false;
          this.$router.push({ 
            path: "/step",
            query: {
              type: this.$route.query.type,
            },
          });
        }
      });
      // 首先获取goodNo及其他nft信息，并保存本地
      // this.$store
      //   .dispatch("getPayNum", this.$route.query.type)
      //   .then((data) => {
      //     let articleNum = 1; //文章的具体楼层数,开篇传1
      //     let articleType = 0; //文章类型，0开篇，1开篇续

      //     if (this.authorId == this.userInfo.userId) {
      //       // 是否是原作者，是的话就楼层正常加1

      //       articleNum = this.agoData.layer + 1;
      //       if (this.agoData.layer == 0) {
      //         articleType = 0;
      //       } else {
      //         articleType = 2;
      //       }
      //     } else {
      //       // 不是原作者
      //       articleNum = this.agoData.layer;
      //       if (this.agoData.layer == 0) {
      //         articleType = 0;
      //       } else {
      //         articleType = 1;
      //       }
      //     }
      //     let param = {
      //       mobileCode: this.mobileCode,
      //       type: this.$route.query.type,
      //       goodNo: data.goodNo,
      //       articleNum, //楼层
      //       articleType, //文章类型，0开篇，1开篇续写；首次开篇或者首次续写传值,2是正常按顺序
      //       articleId: this.agoId, //文章id,开篇传0
      //     };
      //     // 如果获取成功了之后，再进入下一页
      //     payNft(param).then(({ respMsg, success }) => {
      //       if (success) {
      //         this.dialogVisible = false;
      //         this.$router.push({
      //           path: "/step",
      //           query: {
      //             type: this.$route.query.type,
      //           },
      //         });
      //       } else {
      //         this.$message.error(respMsg);
      //       }
      //     });
      //   })
      //   .catch((err) => {
      //     this.$message(err);
      //   });
    },
    getCode() {
      let TIME_COUNT = 60;
      if (!this.code.timer) {
        this.code.count = TIME_COUNT;
        this.code.show = false;
        this.code.dis = true;
        this.code.timer = setInterval(() => {
          if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
            this.code.count--;
          } else {
            this.code.dis = false;
            this.code.show = true;
            clearInterval(this.code.timer);
            this.code.timer = null;
          }
        }, 1000);
      }
    },
    refresh() {
      this.isRefresh = true;
    },
    pay() {
      this.dialog1 = true;
    },
    yesUpdeta() {
      this.dialogVisible = true;
    },
    copy() {
      this.$copyText("0x8e06b884026dccfd7d8bd1240a3821914d5bbca1").then(
        () => {
          this.$message.success("已成功复制到粘贴板");
        },
        () => {
          this.$message("复制失败，请手动复制！");
        }
      );
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      accountId: (state) => state.user.accountId,
      agoId: (state) => state.page.agoId,
    }),
  },
};
</script>

<style lang="less" scoped>
.Create {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url(../../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    .title {
      font-family: Alibaba PuHuiTi 2;
      font-size: 20px;
      color: #fffbfb77;
      height: 60px;
    }
    .kuang {
      display: flex;
      justify-content: center;
      height: 48px;
      width: 420px;
      margin: 18px 0;
      align-items: center;
      border-radius: 20px;
      padding: 0 20px;
      background: #06070c70;
      color: #fffbfb;
      position: relative;
      .shuoMing {
        width: 500px;
        height: 600px;
        background: url(../../assets/images/msgBg.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -90px;
        left: 460px;
        padding: 20px 10px 20px 50px;
        color: #828282;
        line-height: 24px;
        font-size: 12px;
        .item {
          padding-left: 24px;
          position: relative;
          i {
            position: absolute;
            color: #01e39b;
            font-size: 18px;
            top: 12px;
            left: -2px;
          }
        }
        .tit {
          font-size: 16px;
          color: #fff;
          line-height: 40px;
        }
      }
    }
    .imgs {
      width: 182px;
      height: 280px;
      // border-radius: 8px;
      // overflow: hidden;
      // border: 4px solid #999;
      img {
        width: 100%;
      }
    }
    .twoImgs {
      display: flex;
      align-items: center;

      .dataImg {
        position: relative;
        box-sizing: border-box;
        height: 280px;
        width: 390px;
        border: solid 6px transparent;
        border-radius: 8px;
        background-image: linear-gradient(#fee, #fee),
          linear-gradient(to right, #ece92a, #f56c6c);
        background-origin: border-box;
        background-clip: content-box, border-box;

        .title {
          box-sizing: border-box;
          width: 100%;
          font-size: 18px;
          position: absolute;
          bottom: 38px;
          padding-top: 8px;
          color: #fff;
          font-weight: 600;
          padding-left: 6px;
          background-color: rgba(0, 0, 0, 0.7);
        }
        .bottom {
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          display: flex;
          padding-left: 8px;
          align-items: center;
          color: #ccc;
          bottom: 0;
          padding-bottom: 10px;
          background-color: rgba(0, 0, 0, 0.7);
          .nickName {
            margin-left: 16px;
          }
        }
      }
    }
    .num {
      display: flex;
      align-items: center;
      width: 182px;
      margin-top: 20px;
      img {
        margin-left: 8px;
      }
    }
    .longline {
      position: relative;
      .loading {
        position: absolute;
        left: -20px;
      }
      .refresh {
        position: absolute;
        right: -50px;
        cursor: pointer;
      }
      .pay {
        position: absolute;
        right: -110px;
        cursor: pointer;
      }
    }
    .button {
      margin-top: 50px;
      width: 180px;
    }
  }
  .contentImg {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .contentRight {
      position: relative;
      flex: 1;
      margin-left: 20px;
      .hui {
        color: #828282;
        height: 30px;
      }
      .hei {
        color: #000;
        height: 30px;
      }
      .imgButton {
        position: absolute;
        right: 30px;
        bottom: 18px;
        cursor: pointer;
      }
    }
  }
  .blackcircle {
    display: flex;
    justify-content: center;
    height: 38px;
    width: 200px;
    margin-bottom: 38px;
    align-items: center;
    border-radius: 20px;
    background: #06070c9b;
    color: #fffbfb;
    img {
      margin-left: 8px;
    }
  }
  .word {
    color: #333;
    height: 40px;
  }
  .haveCode {
    color: #828282;
    height: 40px;
    display: flex;
    align-items: center;
    i {
      font-size: 22px;
      margin-right: 6px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .footerBut {
      width: 200px;
    }
  }
}
</style>
