//统一管理项目全部接口的文件
//获取真实服务器的接口的数据利用当前axios
import request from './request';
//如果获取mock接口的数据，利用底下axios

//首页数据
export const search = (data) => request({
    url: '/article/list',
    method: 'post',
    headers:{
        isPrivateKey:true
    },
    data
});

//详情数据
export const detail = (data) => request({
    url: '/article/detail',
    method: 'post',
    headers:{
        isPrivateKey:true
    },
    data
});
//取消(收藏/点赞)
export const update = (data) => request({
    url: '/collect/update',
    method: 'post',
    data
});